
import banner from "../assets/images/portfolio/pro-web-image.jpg";

const MainBanner = ({ resultRef }) => {

    return (
		<div id="home" className="saas-banner-area mt-100">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="container">
						<div className="saas-banner-content">
							<h2 style={{color:'white'}}>
								Impressionz <span> Tax Services</span> & 
								<span>&nbsp;Software </span>
								{/* &nbsp;tax <span>business!</span> */}
							</h2>
						</div>
						<div className="saas-banner-img">
							<img src={banner} alt='banner 1' style={{borderRadius: '25px'}} />
						</div>
						<div className="saas-banner-content">
							<br /><br />
							<h3 style={{fontSize: '30px', color:'white', textAlign: 'center'}}>Your <span>Six Figure</span> Business <span> Starts Here</span></h3>
							<h3 style={{color:'white'}}>Tax Preparers Needed</h3>
							<h3 style={{color:'white'}}>* Quality * Impact * Success</h3>
							
						</div>
						<button onClick={()=>{resultRef.current.scrollIntoView({ behavior: "smooth" })}} className='button mx-3'>
							CLICK HERE FOR PROMOTIONS
						</button>
					</div>
				</div>
			</div>
		</div>
    )
};

export default MainBanner;