
import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const Success = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        
    })

    return <>
        <br /><br /><br />
        <div id="contact" className="contact-area personal-contact-area ptb-100">
        <div className="container-fluid">
        {
            location.state !== null ?
            <div className="section-title">
                
                <h5>Thank you for your payment!</h5>

                <div className="container justify-items-center card " style={{width: '25rem'}}>
                    <div className="card-body text-start">
                        <h5 className="card-title text-center">Purchase Details</h5>
                        <p className="card-text"><strong>Name: </strong>{location.state.orderInfo.payer.name.given_name} {location.state.orderInfo.payer.name.surname}</p>
                        <p><strong>Email: </strong>{location.state.orderInfo.payer.email_address}</p>
                        <p><strong>{location.state.deal} Package: </strong>${location.state.orderInfo.purchase_units[0].amount.value}</p>
                        <p><strong>PayPal Order ID: </strong>{location.state.orderInfo.id}</p>
                    </div>
                </div>
                
                <h5 className="mt-5">
                    Upload the documents for the {location.state.deal} Package.
                </h5>
                <h5 className="mt-3">You can save your progress and finish later.</h5>
                <h5 className="mt-3">
                    Use our Secure Form!
                </h5>
                <button className="mt-3 btn btn-primary" type='button'>
                    <a 
                        href={`${process.env.REACT_APP_FORM_URL}`}
                        target="_blank"
                        rel='noreferrer'
                        > 
                        Upload Your Documents
                    </a>
                </button>
            </div> :
            <div className="section-title" onLoad={setTimeout(() => {navigate('/')}, 3000)}>
                <h3>You don't have any current purchases</h3>
                <h3>Rerouting you to homepage</h3>
            </div>
        }
        </div>
    </div>
    </>;
}
 
export default Success;