
import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import paypal from '../assets/images/portfolio/paypal.png'
import paypal2 from '../assets/images/portfolio/paypal2.png'
import PayPal from './PayPal.jsx';

const Pricing = forwardRef((props, ref) => {
    const [price, setPrice] = useState(0);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [agree3, setAgree3] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [promo, setPromo] = useState(true);

    const regularPriceHTML = 'Regular Price!';
    const promoDate = '31 Dec 2021';
    const earlySpecialHTML = `Offer Expires ${promoDate}`;

    function checkPromo() {
        if(Date.parse(`${promoDate} 00:00:00 EST`) < new Date()) setPromo(false);
    };

    function callEverySecond() {
        setInterval(checkPromo, 1000 * 1);// checks every second
    };

    useEffect(() => {
        let today = new Date();
        checkPromo();
        if (today.getSeconds() === 0) { // You can also check for minutes, hours, etc
            callEverySecond();
        } else {
            today.setHours(today.getHours());
            today.setMinutes(today.getMinutes());
            today.setSeconds(today.getSeconds() + 1);// adds 1 second to the current time
            let difference = today - new Date();
            setTimeout(callEverySecond, difference);
        }
    },[refresh, promo]);

    const deals = {
        taxOffice: promo ? 299 : 499,
        taxAffiliate: promo ? 399 : 599,
        upgradedMentorship: promo ? 499 : 899,
    };
    
    const type = {
        taxOffice: 'Tax Office',
        taxAffiliate: 'Tax Affiliate',
        upgradedMentorship: 'Mentorship'
    };
    
    const styleTrainingPromo = {
        fontSize: '0.87em',
    };

    const stylePricingHeight = {
        height: promo ? '84.5vh' : '75.5vh',
    };

    const styleNavLinkTaxAccellerator = {
        textDecoration: "underline",
        fontSize: '0.87em',
        color: 'black'
    };

    const getRefresh = () => {
        if(refresh === true) setRefresh(false);
        else setRefresh(true);
    };
    
    const checkboxHandler = (choice) => {
        if(choice === 'agree1') {
            if((document.getElementById('agree1').checked === true)){
                setAgree1(true);
                setPrice(deals.taxOffice);
                setAgree2(false);
                setAgree3(false);
                document.getElementById('agree2').checked = false;
                document.getElementById('agree3').checked = false;
            };
            if((document.getElementById('agree1').checked === false)) {
                setPrice(0);
                setAgree1(false);
                setAgree2(false);
                setAgree3(false);
                document.getElementById('agree2').checked = false;
                document.getElementById('agree3').checked = false;
                getRefresh();
            };
        };
        if(choice === 'agree2'){
            if(document.getElementById('agree2').checked === true){
                setAgree2(true);
                setPrice(deals.taxAffiliate);
                setAgree1(false);
                setAgree3(false);
                document.getElementById('agree1').checked = false;
                document.getElementById('agree3').checked = false;
            };
            if(document.getElementById('agree2').checked === false) {
                setPrice(0);
                setAgree1(false);
                setAgree2(false);
                setAgree3(false);
                document.getElementById('agree1').checked = false;
                document.getElementById('agree3').checked = false;
                getRefresh();
            };
        };
        if(choice === 'agree3'){
            if(document.getElementById('agree3').checked === true){
                setAgree3(true);
                setPrice(deals.upgradedMentorship);
                setAgree1(false);
                setAgree2(false);
                document.getElementById('agree1').checked = false;
                document.getElementById('agree2').checked = false;
            };
            if(document.getElementById('agree3').checked === false) {
                setPrice(0);
                setAgree1(false);
                setAgree2(false);
                setAgree3(false);
                document.getElementById('agree1').checked = false;
                document.getElementById('agree2').checked = false;
                getRefresh();
            };
        };
    };
    
    
    return <>
        <div ref={ref} id="pricing" className="pricing-area saas-pricing-area pt-100 pb-70">
            <div className="container">
                <div className="section-title saas-title">
                    <h2>PROMO Software Packages</h2>
                    <h3>Software Available in English & Spanish</h3>
                    <h3>IRS Certified Training in English & Spanish Available
                        <NavLink to="/contact" className='navlink'>
                            Contact Us For Details
                        </NavLink>
                    </h3>
                </div>

                <div className="tab">
                    <div className="row">
                        <div className="col-12">
                            <div className="price-tab">
                                <ul className="tabs">
                                    <li>
                                        Yearly Pricing
                                    </li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-12">
                            <div className="tab_content">
                                <div id="tab2" className="tabs_item">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="single-pricing" style={stylePricingHeight}>
                                                <h3 className='accelerator'>Tax Office Software</h3>
                                                {
                                                    promo ? 
                                                        <>  
                                                            <h3>{`Regular Price $699`}</h3>
                                                            <h3>{earlySpecialHTML}</h3>
                                                        </>
                                                    :
                                                    <></>
                                                }
                    
                                                <div className="price">
                                                    <h2>${deals.taxOffice}</h2>
                                                </div>
                    
                                                <ul>
                                                    <li style={styleTrainingPromo}> IRS Certified Training Available
                                                        <div>
                                                            <NavLink to="/contact" style={styleNavLinkTaxAccellerator}>
                                                                Contact Us For Details
                                                            </NavLink>
                                                        </div>  
                                                    </li>
                                                    <li><strong>Additional Perks</strong></li>
                                                    <li><strong>Free </strong>- Business Software</li>
                                                    <li><strong>Free </strong>- Mentorship & Marketing Training</li>
                                                    <li><strong>Free </strong>- Co-Branded Taxes To Go App</li>
                                                    <li><strong>Free </strong>- Top Tier Tech Support</li>
                                                    <li><strong>Free - How To Apply For an EFIN & EIN </strong> - I Personally Guide You Through <strong>YOUR </strong> Application</li>
                                                </ul>

                                                <div className="container">
                                                <div>
                                                    <input type="checkbox" id="agree1" onChange={()=>{ checkboxHandler('agree1') }}/>
                                                    <label htmlFor="agree1"> &nbsp; <strong>I'm Ready To Checkout!</strong></label>
                                                    </div>
                                                    
                                                    {agree1 ? <PayPal refresh={refresh} price={deals.taxOffice} deal={type.taxOffice} id={1}/> : 
                                                    <img src={paypal} alt="paypal" /> }
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-6">
                                            <div className="single-pricing active" style={stylePricingHeight}>
                                                <h3 className='incubator'>Tax Affiliate</h3>
                                                <h3>Earn While You Learn</h3>
                                                {
                                                    promo ? 
                                                        <>  
                                                            <p>Limited Time!</p>
                                                            <h3>{`Regular Price $499`}</h3>
                                                            <h3>{earlySpecialHTML}</h3>
                                                        </>
                                                    :
                                                    <></>
                                                }

                                                <div className="price">
                                                    <h2 style={{color: 'white'}}>${deals.taxAffiliate}</h2>
                                                </div>

                                                <ul>
                                                    <li style={styleTrainingPromo}> WE'RE LOOKING FOR MOTIVATED AND DRIVEN INDIVIDUALS WHO ARE READY TO MAKE 5-6 FIGURES IN 2-4 MONTHS!!   
                                                    </li>
                                                    <li><strong>Package Includes:</strong></li>

                                                    <li><strong>70/30</strong> Split Prep-Fees</li>
                                                    <li><strong>Free Software</strong> - Full Refund After 30 Funded Bank Products</li>
                                                    <li><strong>Free</strong> - PTIN Assistance</li>
                                                    <li><strong>Tax Software</strong>  User ID</li>
                                                    <li><strong>Free </strong> - Bank Registration / Software Set-up</li>
                                                    <li><strong>Free </strong> - Basic Tax Training</li>
                                                    <li><strong>Free </strong> - Top Tier Tech Support</li>
                                                    <li><strong>Free</strong> - One Full Year Mentorship.</li>
                                                    <li>Click Below If You Are Ready To Make $$$</li>
                                                </ul>
                                                
                                                <div className="container">
                                                    <div>
                                                        <input type="checkbox" id="agree2" onChange={()=>checkboxHandler('agree2')}/>
                                                        <label style={{color: 'white'}} htmlFor="agree2"> &nbsp; <strong>I'm Ready To Make $$$</strong></label>
                                                    </div>
                                                    {agree2 ? <PayPal refresh={refresh} price={deals.taxAffiliate} deal={type.taxAffiliate} id={2}/> : 
                                                    <img src={paypal2} alt="paypal" /> }
                                                </div>
                                            </div>
                                        </div>
                    
                                        <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                            <div className="single-pricing" style={stylePricingHeight}>
                                                <h3 className='accelerator'>Upgraded Mentorship</h3>
                                                {
                                                    promo ? 
                                                        <>  
                                                            <h3>{`Regular Price $899`}</h3>
                                                            <h3>{earlySpecialHTML}</h3>
                                                        </>
                                                    :
                                                    <></>
                                                }
                    
                                                <div className="price">
                                                    <h2>${deals.upgradedMentorship}</h2>
                                                </div>
                    
                                                <ul>
                                                    <li style={{color: '#0e65d7'}}><strong>How many times have you lost business because you forgot to call back, reply, etc., or you were just too busy?</strong></li>
                                                    <li style={{color: '#0e65d7'}}><strong>Come learn how to automate your life and use the free time to build and grow your Tax Business!</strong></li>
                                                    <li>Same Options As Tax Office Software</li>
                                                    <li><strong>Additional Perks</strong></li>
                                                    <li>Schedule <strong>One-On-One</strong> Mentorship Sessions</li>
                                                    <li><strong>Advanced</strong> Marketing Strategies</li>
                                                    <li><strong>Email  * Social Media * Etc</strong></li>
                                                </ul>

                                                <div className="container">
                                                <div>
                                                    <input type="checkbox" id="agree3" onChange={()=>{ checkboxHandler('agree3') }}/>
                                                    <label htmlFor="agree3"> &nbsp; <strong>I'm Ready To Checkout!</strong></label>
                                                    </div>
                                                    
                                                    {agree3 ? <PayPal  refresh={refresh} price={deals.upgradedMentorship} deal={type.upgradedMentorship} id={3}/> : 
                                                    <img src={paypal} alt="paypal" /> }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

})

export default Pricing;