
import React, { useEffect, useState } from "react";
import { useFormspark } from "@formspark/use-formspark";

// Form initial state
const INITIAL_STATE = {
	name: "",
    email: "",
    number: "",
    subject: "",
	webHosting: "",
	taxOfficeSoftware: "",
	serviceBureau: "",
    text: "",
};

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
    })
	// 
	const [contact, setContact] = useState(INITIAL_STATE);
	const [submit, submitting] = useFormspark({
		formId: process.env.REACT_APP_FORMSPARK_FORM_ID,
	});
// https://submit-form.com/X6jHMDHG
    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact(prevState => ({ ...prevState, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { name, email, number, subject, webHosting, taxOfficeSoftware, serviceBureau, text } = contact;
            const payload = { name, email, number, subject, webHosting, taxOfficeSoftware, serviceBureau, text };
            
			await submit({ payload });
            setContact(INITIAL_STATE);
			alert('Form Submitted!');
        } catch (error) {
            console.log(error)
        }
    };

    return (
		// <head>
		// 	<title>reCAPTCHA demo: Simple page</title>
		// 	<script src="https://www.google.com/recaptcha/api.js" async defer></script>
		// </head>
        <div id="contact" className="contact-area personal-contact-area ptb-100">
			<br /><br /><br />
			<div className="container">
				<div className="section-title personal-title">
					<h2>Please contact us if you have a question about us, what we do, or how we can help you.</h2>
				</div>

				<div className="contact-form">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="form-group">
									<input 
										type="text" 
										name="name" 
										placeholder="Name" 
										className="form-control" 
										value={contact.name.toLowerCase()}
										onChange={handleChange} 
										required 
									/>
								</div>
							</div>
							<div className="col-lg-6 col-sm-6">
								<div className="form-group">
									<input 
										type="text" 
										name="email" 
										placeholder="Email" 
										className="form-control" 
										value={contact.email.toLowerCase()}
										onChange={handleChange} 
										required 
									/>
								</div>
							</div>
							<div className="col-lg-6 col-sm-6">
								<div className="form-group">
									<input 
										type="text" 
										name="number" 
										placeholder="Phone number" 
										className="form-control" 
										value={contact.number}
										onChange={handleChange} 
										required 
									/>
								</div>
							</div>
							<div className="col-lg-6 col-sm-6">
								<div className="form-group">
									<input 
										type="text" 
										name="subject" 
										placeholder="Subject" 
										className="form-control" 
										value={contact.subject.toLowerCase()}
										onChange={handleChange} 
										required 
									/>
								</div>
							</div>
							<div className="col-lg-12 col-sm-12">
								<div className="container">
									<h5>Select One Or More Categories</h5>
								</div>
							</div>
							<div className="col-lg-4 col-sm-4">
								<div className="form-check">
									<h6>Web Hosting/Website
										<input 
										style={{margin: '0px'}}
										type="checkbox"
										name="webHosting"
										className="form-check-input"
										indeterminate={contact.webHosting}
										onChange={handleChange}
										/>
									</h6>
								</div>
							</div>
							<div className="col-lg-4 col-sm-4">
								<div className="form-check">
									<h6>Tax Office Software
										<input 
										style={{margin: '0px'}}
										type="checkbox"
										name="taxOfficeSoftware"
										className="form-check-input"
										indeterminate={contact.taxOfficeSoftware}
										onChange={handleChange}
										/>
									</h6>
								</div>
							</div>
							<div className="col-lg-4 col-sm-4">
								<div className="form-check">
									<h6>Service Bureau Program
										<input 
										style={{margin: '0px'}}
										type="checkbox"
										name="serviceBureau"
										className="form-check-input"
										indeterminate={contact.serviceBureau}
										onChange={handleChange}
										/>
									</h6>
								</div>
							</div>
							
							<div className="col-lg-12 col-md-12">
								<div className="form-group">
									<textarea 
										name="text" 
										cols="30" 
										rows="8" 
										placeholder="Write your message..." 
										className="form-control" 
										value={contact.text}
										onChange={handleChange} 
										required 
									/>
								</div>
							</div>
							<div className="col-lg-12 col-sm-12">
								<button type="submit" disabled={submitting} className="personal-btn">
									Send Message
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
    )
}

export default Contact;