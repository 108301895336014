
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function Redirect() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 2000)
    })

    return <>
        <br /><br /><br />
        <div id="contact" className="contact-area personal-contact-area ptb-100">
            <div className="container-fluid">
                <div className="section-title personal-title">
                    <br />
                    <h2>Sorry That Page Does Not Exist!</h2>
                    <h2>You're Being Transfered To The Homepage</h2>
                </div>
            </div>
        </div>
    </>;
}
