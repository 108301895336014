
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import logo from "./assets/images/portfolio/website-small.png";

const Footer = () => {

    const currentYear = new Date().getFullYear();
    
    return (
        <>
            <div className="footer-area personal-footer-area pt-100 pb-70">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="single-footer-widget">
                                <NavLink to="/">
                                    <img src={logo} alt="logo" />
                                </NavLink>
                                <p style={{textAlign: 'center'}}>
                                    All The <strong><span>Tools & Support</span></strong> 
                                    &nbsp;You Need For <strong><span></span></strong> 
                                     <strong><span>Your Personal & Business Growth </span></strong> And To 
                                    <strong><span>&nbsp;Help You </span></strong>  Make
                                    <strong><span>&nbsp;Training Easy</span></strong> 
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 ">
                            <div className="single-footer-widget">
                                <h3>Contact us</h3>
                                <div className="social-area">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/ImpressionzTaxServices" target="_blank" rel='noreferrer'>
                                                <Icon icon="bx:bxl-facebook" className='icon'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/antoniettahinton/" target="_blank" rel='noreferrer'>
                                                <Icon icon="bx:bxl-instagram" className='icon'/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="copy-right personal-copy-right">
                <div className="container">
                    <span>
                        Copyright &copy;{currentYear} Metaverse Group. Designed by&nbsp;
                        <a href="https://metaversegroup.tech/contact" target="_blank" rel='noreferrer'>
                            Analitico
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
}

export default Footer;