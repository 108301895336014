
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";


const PayPal = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");
  const [orderData, setOderData] = useState({});
  
  const navigate = useNavigate();

  const initialOptions = {
    "client-id": process.env.REACT_APP_YOUR_CLIENT_ID_LIVE,
    "enable-funding": "venmo",
    currency: "USD",
  };

  // creates a paypal order
  const createOrder = async (data, actions) => {
    return await actions.order
      .create({
        purchase_units: [
          {
            description: `Professional ${props.deal} Software Package`,
            amount: {
              currency_code: "USD",
              value: props.price,
            },
          },
        ],
        // remove the applicaiton_context object if you need your users to add a shipping address
        // application_context: {
        //   shipping_preference: "NO_SHIPPING",
        // },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    const orderInfo = await actions.order.capture(data.orderID).then( (results) => {return results});

    return await actions.order.capture().then(function (payerData) {
      
      const { payer } = payerData;
      
      setOderData(orderInfo);
      setBillingDetails(payer);
      setSucceeded(true);

      // Show a success message within this page, e.g.
      // Or go to another URL:
      setTimeout(() => {
        // actions.redirect('/success');
        navigate('/Success', { state: { orderInfo: orderInfo, deal: props.deal } });
      }, 2000)

    });
  };
  // handles payment errors
  const onError = async (data, actions) => {
    setPaypalErrorMessage("Something went wrong with your payment");
  };

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        <div id="smart-button-container">
          <div style={{ textAlign: "center", overflow: "clip" }}>
            <div style={{ marginBottom: "1.25rem", marginTop: "1.25rem" }}>
              <select id="item-options">
                <option
                  value={`Professional ${props.deal} Software Package`}
                  price={props.price}
                >
                  {props.deal} ${props.price}
                </option>
              </select>
              <select
                style={{ visibility: "hidden" }}
                id="quantitySelect"
              ></select>
            </div>
          </div>
        </div>
        <div id="paypal-button-container">
          <PayPalButtons
            style={{
              shape: 'rect',
              color: 'silver',
              // layout: "vertical",
              // shape: "pill",
            }}

            forceReRender={props.refresh}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </div>
      </PayPalScriptProvider>
    </>
  );
};

export default PayPal;
