
const Advantages = () => {


	const stylePartnerHeight = { height: '23vh' };
	const spanStyle = {
		'fontSize': '23px'
	};

    return (
		<div id="services" className="sass-services-area pt-100 pb-70">
			<div className="container justify-items-center">
				<div className="row align-items-center pb-100">
				    <div className="saas-banner-content">
						<h3 style={{textAlign: 'center'}}>
							All The <span style={spanStyle}>Tools & Support</span> 
							&nbsp; <span style={spanStyle}></span>
							You Need For <span>Your Personal & Business Growth</span> And To 
							<span style={spanStyle}>&nbsp;Help You </span> Make 
							<span style={spanStyle}>&nbsp;Training Easy</span>
						</h3>
					</div>
                    <div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box" style={stylePartnerHeight}>
									<i className="flaticon-data-storage"></i>
									<h3>Cloud Based Application</h3>
									<p>Get secure access to your clients and data on your laptop or mobile device without the need to install software</p>
								</div>
							</div>

							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box" style={stylePartnerHeight}>
									<i className="flaticon-secure"></i>
									<h3>Learn & Grow</h3>
									<p>Our proven training and marketing program will help you close more clients and allow you to focus on building and growing your business</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box" style={stylePartnerHeight}>
									<i className="flaticon-smartphone"></i>
									<h3>Taxes To Go!</h3>
									<p>Securely upload documents and ID's or view your tax return(s) with <strong>Taxes To Go</strong> mobile app</p>
								</div>
							</div>

							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box" style={stylePartnerHeight}>
									<i className="flaticon-solution"></i>
									<h3>IRS Certified Training</h3>
									<p>We have IRS Certified Training available to help you stay on top of your skills and able to provide the best tax consultation possible</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Advantages;